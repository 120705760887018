<template>
    <div>
        <b-modal v-model="modals.translation" :title="$t('general.translation')" centered
            content-class="card form-rounded-inputs card-ecart" size="lg" header-class="card-header"
            @hidden="fnResetTranslation()" hide-header-close>
            <v-observer tag="form" ref="formProductTranslation" @submit.prevent="fnValidateTranslation()">

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.language') ">
                    <div class="form-group">
                        <label>
                            <span v-text="$t('general.language')"></span>
                        </label>
                        <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                            <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                                v-model="newTranslation.language" :disabled="newTranslation.id">
                                <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                                <option class="text-capitalize" :value="language.language"
                                    v-for=" (language,index) in getAvailableLanguages" :key="index"
                                    v-text="language.language"></option>
                                    <option :value="newTranslation.language" v-if="newTranslation.id" v-text="newTranslation.language" ></option>
                            </select>
                            <div class="input-group-append">
                                <label class="input-group-text"> <i class="fa fa-language"></i> </label>
                            </div>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                            {{ errors[0] }}
                        </div>
                    </div>
                </v-validation>

                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.name')">
                    <div class="form-group" id="product-name">
                        <label for="" class="w-100">
                            <span v-text="$t('general.form.name')"></span>
                        </label>
                        <input type="text" class="form-control" v-model=" newTranslation.name "
                            :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="invalid-feedback " v-if="errors.length > 0">
                            {{ errors[0] }}
                        </div>
                    </div>
                </v-validation>

                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.description')">
                    <div class="form-group" id="product-description">
                        <label for="" class="w-100">
                            <span v-text="$t('general.form.description')"></span>
                        </label>
                        <vue-editor class=" " v-model="newTranslation.description" :editor-toolbar="CONST.CUSTOMTOOLBAR"
                            :class=" { 'is-invalid ':errors.length > 0 } "></vue-editor>
                        <div class="invalid-feedback " v-if="errors.length > 0">
                            {{ errors[0] }}
                        </div>
                    </div>
                </v-validation>

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.tags') ">
                    <div class="form-group" id="product-tags">
                        <label for="" class="w-100">
                            <span v-text="$t('general.form.tags')"></span> <span> (
                                {{$t('general.form.minimum')}} 5 ) </span>
                        </label>
                        <div class="input-group ">
                            <b-form-tags v-model="newTranslation.tags" class=" "
                                :placeholder="$t('general.form.addTags') " tag-variant="primary" separator=","
                                :class=" { 'is-invalid border-danger':errors.length > 0 } ">
                            </b-form-tags>
                            <div class="input-group-append">
                                <span class="input-group-text "> <i class="fa fa-tags"></i> </span>
                            </div>
                            <div class="invalid-feedback " v-if="errors.length > 0">
                                {{ errors[0] }}
                            </div>
                        </div>
                    </div>
                </v-validation>

                <input type="submit" hidden>
            </v-observer>

            <template v-slot:modal-footer>
                <p class="text-right">
                    <b-button variant="none" class=" mr-2" @click=" fnResetTranslation() "
                        v-text="$t('general.button.close')">
                    </b-button>
                    <button class="btn btn-success" :disabled="sendingData">
                        <span v-if="!sendingData" @click=" fnValidateTranslation()"
                            v-text=" $t('general.button.update') ">
                        </span>
                        <i v-else class="fa fa-spinner fa-spin "></i>
                    </button>
                </p>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import {
        VueEditor
    } from "vue2-editor";

    import {
        mapGetters,
        mapState,
        mapMutations
    } from 'vuex'
    export default {
        components: {
            VueEditor,
        },
        data() {
            return {
                sendingData: false,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['modals', 'loading', 'productInformation', 'variantId', 'newTranslation','productInformationTranslations']),
            ...mapGetters('EcartProduct', ['getAvailableLanguages']),
        },
        watch: {

        },
        methods: {
            ...mapMutations('EcartProduct', ['deleteTransalation', 'changeModal','resetTranslation']),
            fnResetTranslation() {
                this.changeModal({
                    translation: false
                });
                this.resetTranslation();
            },

            async fnValidateTranslation() {
                if (await this.$refs['formProductTranslation'].validate()) {
                    this.sendingData = true;
                    let {
                        name,
                        description,
                        tags,
                        language,
                    } = this.newTranslation;
                    let tempData = {
                        name,
                        description,
                        tags,
                        language,
                    }
                    await axios.put(`products/translations/${this.productInformation.id}`, [tempData]).then((response) => {
                        this.$toasted.global.infoMessage(this.$t('toasted.info.productTranslationUpdated'));
                        let tempIndex = this.productInformationTranslations.findIndex(trans => (trans.language ==
                            tempData.language));
                            if(tempIndex >= 0){
                                this.productInformationTranslations[tempIndex] = tempData;
                            }else{
                                this.productInformationTranslations.push( tempData );
                            }
                        setTimeout(() => {
                            this.fnResetTranslation();
                        }, 300);
                    }).catch(error => { });
                    this.sendingData = false;
                } else {
                    this.fnGlobalScrollToError(this.$refs['formProductTranslation'].refs, true);
                }
            },
        }
    }
</script>