<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h6 v-text="$t('general.translations')"></h6>
                </div>
                <div class="col-auto">
                    <button class="btn btn-success circle-25" v-if="getAvailableLanguages.length > 0 "
                        @click=" changeModal({ translation:true}) ">
                        <i class="fa fa-plus-circle"></i>
                    </button>
                </div>
            </div>
            <table class="table table-striped table-bordered animated fadeIn mt-2">
                <tbody>
                    <tr>
                        <td v-for=" (translation,index) in productInformationTranslations" :key="index">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col">
                                            <p class="text-capitalize" v-text="translation.language"></p>
                                        </div>
                                        <div class="col-auto d-flex">

                                            <button class="btn btn-light btn-sm circle-20"
                                                @click="editTranslation(index)">
                                                <i class="fa fa-pencil-alt fa-sm text-info"></i>
                                            </button>
                                            <button class="btn btn-light btn-sm circle-20  ml-2"
                                                @click="fnApiDeleteTranslation(translation.language)">
                                                <i class="fa fa-spinner fa-spin "
                                                    v-if=" (deletingTranslation != null) && (deletingTranslation == translation.language) "></i>
                                                <i class="fa fa-trash-alt fa-sm text-danger" v-else></i>
                                            </button>


                                            <!-- <button class="btn btn-link btn-sm" @click="editTranslation(index)"
                                                v-text="$t('general.button.edit')"></button>
                                            / -->
                                            <!-- <button class="btn btn-link btn-sm text-muted"
                                                @click="fnApiDeleteTranslation(translation.language)">
                                                <span v-text="$t('general.button.delete')"
                                                    v-if=" !deletingTranslation "></span>
                                                <i class="fa fa-spinner fa-spin " v-else></i>
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card" v-if="productInformationTranslations.length == 0">
                <div class="card-body p-2 p-md-4 bg-light ">
                    <p class="text-muted text-center">
                        <small class="f-w-500" v-text="$t('dashboard.product.languages.message')"></small>
                    </p>
                </div>
            </div>
        </div>
        <ProductTranslationsModal />
    </div>
</template>

<script>
    import ProductTranslationsModal from './product-translations-modal.vue'
    import {
        mapGetters,
        mapMutations,
        mapState
    } from 'vuex'
    export default {
        components: {
            ProductTranslationsModal,
        },
        data() {
            return {
                deletingTranslation: null,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['productInformation', 'productInformationTranslations']),
            ...mapGetters('EcartProduct', ['getAvailableLanguages']),
        },
        methods: {
            ...mapMutations('EcartProduct', ['deleteTransalation', 'changeModal', 'editTranslation']),

            async fnApiDeleteTranslation(language) {
                this.deletingTranslation = language;
                await axios.delete(`products/translations/${this.productInformation.id}/${language}`).then((
                    response) => {
                    this.$toasted.global.infoMessage(this.$t('toasted.info.productTranslationDelete'));
                    this.deleteTransalation(language);
                }).catch(error => {});
                this.deletingTranslation = null;
            },

        }
    }
</script>

<style>

</style>